// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-augmented-recruitment-tsx": () => import("./../../../src/pages/augmented-recruitment.tsx" /* webpackChunkName: "component---src-pages-augmented-recruitment-tsx" */),
  "component---src-pages-bot-tsx": () => import("./../../../src/pages/bot.tsx" /* webpackChunkName: "component---src-pages-bot-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contractors-tsx": () => import("./../../../src/pages/contractors.tsx" /* webpackChunkName: "component---src-pages-contractors-tsx" */),
  "component---src-pages-family-tsx": () => import("./../../../src/pages/family.tsx" /* webpackChunkName: "component---src-pages-family-tsx" */),
  "component---src-pages-headhunting-tsx": () => import("./../../../src/pages/headhunting.tsx" /* webpackChunkName: "component---src-pages-headhunting-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-recruiter-tsx": () => import("./../../../src/pages/recruiter.tsx" /* webpackChunkName: "component---src-pages-recruiter-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-scout-tsx": () => import("./../../../src/pages/scout.tsx" /* webpackChunkName: "component---src-pages-scout-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

